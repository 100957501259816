<i18n>
[
    "global__cancel",
    "global__retail",
]
</i18n>

<template>
    <div
        v-if="product"
        :class="{
            'u-spacer--2': useBottomMargin,
        }"
        class="c-catalog-tile"
    >
        <div class="c-catalog-tile__image-and-info">
            <div class="c-catalog-tile__image-container">
                <div
                    class="c-catalog-tile__fade"
                    :class="{
                        'c-catalog-tile__fade--in-view': hasEnteredViewport
                    }"
                >
                    <div
                        v-if="hasEnteredViewport && isMounted"
                        class="c-catalog-tile__image-container-inner"
                    >
                        <BaseLink
                            :to="productRoute"
                            class="u-display--block c-catalog-tile__image-container-inner__link"
                            @click="handleProductClick"
                        >
                            <ProductImage
                                :src="productImage"
                                :title="product.displayName"
                                :choiceId="product.choiceId"
                                :imagePreset="imagePreset"
                                :isLazyImage="true"
                                :shouldDisplayImage="true"
                                :fadeInAfterLoaded="true"
                                :allowFocusOutline="true"
                                class="c-catalog-tile__image"
                            />
                        </BaseLink>
                        <ClosetBadge
                            v-if="showClosetButton"
                            :isSmall="smallClosetButton || $mediaQueries.isSmallish"
                            class="c-catalog-tile__closet-badge"
                        />
                    </div>
                </div>
            </div>
            <div
                class="c-catalog-tile__fade c-catalog-tile__info-section"
                :class="{
                    'c-catalog-tile__fade--in-view': hasEnteredViewport,
                }"
            >
                <BaseLink
                    v-if="!hideInfo"
                    :to="productRoute"
                    class="u-display--block c-catalog-tile__info-section__link"
                    tabindex="-1"
                    @click="handleProductClick"
                >
                    <div
                        :class="{
                            'c-catalog-tile__info-container': true,
                            'c-catalog-tile__info-container--light': lightInfoContainer,
                        }"
                    >
                        <p class="o-text--heading-6 u-text--black c-catalog-tile__display-name">
                            {{ product.displayName }}
                        </p>
                        <div class="c-catalog-tile__product-label o-text--caption u-text--gray u-text--center">
                            <div
                                :class="{
                                    'c-catalog-tile__product-label-brand': hidePrice && hasEnteredViewport,
                                    'c-catalog-tile__product-label-brand--mobile': !(hidePrice && hasEnteredViewport)
                                }"
                                class="o-text--caption u-text--gray"
                            >
                                {{ brandName }}
                            </div>
                            <template v-if="hidePrice && hasEnteredViewport">
                                <div class="u-text--medium-gray">
                                    |
                                </div>
                                <div class="c-catalog-tile__product-label-price o-text--caption u-text--gray">
                                    {{ retailPrice }}
                                </div>
                            </template>
                        </div>
                    </div>
                </BaseLink>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import { GLOBAL_EVENT } from '~coreModules/core/js/store';

import { URBN_BRANDS } from '~coreModules/core/js/constants';
import { PRODUCT_CLICK } from '~coreModules/core/js/global-event-constants';
import { CATALOG_IMAGE_VIEWER_MAX_NUM_IMAGES } from '~coreModules/catalog/js/catalog-constants';

import { CATALOG_GRID_LAYOUTS } from '~modules/catalog/js/catalog-constants';
import { PRODUCT_AFFILIATION } from '~modules/analytics/js/analytics-constants';

import ProductImage from '~coreModules/core/components/ui/ProductImage.vue';
import ClosetBadge from '~modules/core/components/ui/ClosetBadge.vue';

export default {
    name: 'CatalogTile',
    components: {
        ProductImage,
        ClosetBadge,
    },
    props: {
        analyticsType: {
            type: String,
            default: null,
        },
        product: {
            type: Object,
            required: true,
        },
        imagePreset: {
            type: String,
            required: true,
        },
        gridLayout: {
            type: String,
            default: CATALOG_GRID_LAYOUTS.LARGE,
        },
        hideInfo: {
            type: Boolean,
            default: false,
        },
        hasEnteredViewport: {
            type: Boolean,
            default: false,
        },
        productTrayId: {
            type: String,
            default: null,
        },
        useBottomMargin: {
            type: Boolean,
            default: true,
        },
        catalogResponseId: {
            type: String,
            default: null,
        },
        lightInfoContainer: {
            type: Boolean,
            default: true,
        },
        index: {
            type: Number,
            default: null,
        },
        productListId: {
            type: String,
            default: '',
        },
        productListName: {
            type: String,
            default: '',
        },
        showClosetButton: {
            type: Boolean,
            default: true,
        },
        smallClosetButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMounted: false,
        };
    },
    computed: {
        productImages() {
            const images = this.product?.images || [];
            if (images.length > CATALOG_IMAGE_VIEWER_MAX_NUM_IMAGES) {
                return images.slice(0, CATALOG_IMAGE_VIEWER_MAX_NUM_IMAGES);
            }
            return images;
        },
        productImage() {
            return this.productImages.length ? this.productImages[0] : '';
        },
        hidePrice() {
            return !(this.$mediaQueries.isExtraSmall && this.gridLayout === CATALOG_GRID_LAYOUTS.LARGE);
        },
        brandName() {
            return this.filterVendorBrands(this.product?.vendorBrands || []);
        },
        retailPrice() {
            const msrp = this.$filters.i18nFormatDollarsNoCents(this.product.msrpValue);

            return `${this.$t('global__retail')} ${msrp}`;
        },
        productRoute() {
            const { colorCode, productSlug } = this.product;

            return `/rent/products/${productSlug}?color=${colorCode}`;
        },
        productAnalyticsData() {
            const {
                choiceId,
                displayName,
                msrpValue,
            } = this.product;

            return {
                productList: {
                    responseId: this.catalogResponseId,
                },
                product: {
                    productListId: this.productListId,
                    productListName: this.productListName,
                    productPrice: msrpValue,
                    productId: choiceId,
                    productName: displayName,
                    productAffiliation: PRODUCT_AFFILIATION,
                    productBrand: this.brandName,
                },
            };
        },
    },
    mounted() {
        this.isMounted = true;
    },
    methods: {
        ...mapActions({
            trackGlobalEvent: GLOBAL_EVENT,
        }),
        filterVendorBrands(vendorBrands) {
            if (!vendorBrands.length) return '';
            if (vendorBrands.length > 1) {
                return vendorBrands.filter(brand => !URBN_BRANDS.includes(brand))[0];
            }

            return vendorBrands[0];
        },
        handleProductClick() {
            this.trackGlobalEvent({
                type: PRODUCT_CLICK,
                data: this.productAnalyticsData,
            });
        },
    },
};
</script>

<style lang='scss'>
    .c-catalog-tile {
        width: 100%;
        background-color: $placeholder-background;
        position: relative;

        @include css-has-supported {
            &:has(.c-carousel__container.focus-visible:focus[data-focus-visible-added]),
            &:has(.c-catalog-tile__image-container-inner__link.focus-visible:focus[data-focus-visible-added]) {
                outline: $focus-outline;
            }
        }

        &__fade {
            opacity: 0;
            transition: opacity .5s ease;

            &--in-view {
                opacity: 1;
            }
        }

        &__image-and-info {
            position: relative;
        }

        &__image-container {
            position: relative;

            &::before {
                position: relative;
                display: block;
                content: "";
                width: 100%;
                padding-top: 150%;
                background-color: $placeholder-background;
            }
        }

        &__image-container-inner {
            position: absolute;
            top: 0;
            width: 100%;

            &__link.focus-visible:focus[data-focus-visible-added] {
                outline: 0;
            }
        }

        &__image {
            width: 100%
        }

        &__closet-badge {
            position: absolute;
            top: 0;
            right: 0;
        }

        &__info-section {
            position: relative;

            &__link {
                outline-offset: -4px;
            }
        }

        &__info-container {
            @include ellipsize;

            background-color: $nu-secondary;
            padding: $nu-spacer-1pt5;
            width: 100%;
            min-height: 40px;
            text-align: center;

            &--light {
                background-color: $nu-white;
            }

            @include breakpoint(medium) {
                padding: $nu-spacer-2;
            }
        }

        &__display-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: $nu-spacer-1;
        }

        &__brand {
            color: $nu-gray;
        }

        &__product-label {
            display: flex;
            width: 100%;
            justify-content: center;

            &-brand,
            &-brand--mobile {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &-brand {
                text-align: right;
                padding-right: $nu-spacer-1;
            }

            &-price {
                text-align: left;
                padding-left: $nu-spacer-1;
            }
        }
    }
</style>
